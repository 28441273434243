import React, { ReactNode, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import HeaderPatientInfo from '../HeaderPatientInfo';
import { PatientInfoVisibility } from '../../types/PatientInfoVisibility';
import classnames from 'classnames';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED,
  servicesManager,
  Secondary,
  appConfig,
  ...props
}: withAppTypes): ReactNode {
  const { t } = useTranslation('Header');
  const [isLightTheme, setIsLightTheme] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isOpenLayout, setIsOpenLayout] = useState(localStorage.getItem('isOpenLayout'));

  const isOpen = localStorage.getItem('isOpenLayout');

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1424px)'); // Adjust the breakpoint as needed
    const handleMediaChange = () => {
      setIsSmallScreen(mediaQuery.matches);
      setIsOpenLayout(localStorage.getItem('isOpenLayout'));
    };

    handleMediaChange();

    mediaQuery.addEventListener('change', handleMediaChange);

    return () => mediaQuery.removeEventListener('change', handleMediaChange);
  }, [localStorage.getItem('isOpenLayout')]);
  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };
  const onClickTab = themeName => {
    setIsLightTheme(themeName);
    localStorage.setItem('theme', themeName);
  };

  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const token = params.get('tokens');
  console.log('tokentokentokentokentoken: ' + token);

  const StudyInstanceUIDs = params.get('StudyInstanceUIDs');
  const seriesInstanceUIDs = localStorage.getItem('SeriesInstanceUID'); //params.get('StudyInstanceUIDs');
  // const handleOpenAretemViewer = () => {
  //   console.log('seriesInstanceUIDsseriesInstanceUIDs', seriesInstanceUIDs, StudyInstanceUIDs);
  //   // const aviewer = `dicomviewer://viewer?seriesId=${seriesInstanceUIDs}&studyId=${StudyInstanceUIDs}`;
  //   // window.location.href =
  //   return window.open(
  //     `dicomviewer://viewer?seriesId=${seriesInstanceUIDs}&studyId=${StudyInstanceUIDs}`,
  //     '_blank'
  //   );
  //   //
  //   // window.open(aviewer, '__blank');
  //   // window.location.href = aviewer;
  // };

  return (
    <NavBar
      isSticky={isSticky}
      {...props}
    >
      <div className="relative h-[68px] items-center ">
        <div className="flex flex-grow-[4] items-center">
          <div className="absolute left-0 top-1/2 ml-2 mb-2 flex -translate-y-1/2 items-center">
            <div
              className={classNames(
                'mr-3 inline-flex items-center',
                isReturnEnabled && 'cursor-pointer'
              )}
              onClick={onClickReturn}
              data-cy="return-to-work-list"
            >
              {/* {isReturnEnabled && (
                <Icon
                  name="chevron-left"
                  className="text-primary-active w-8"
                />
              )} */}
              <div className="ml-1">
                {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
                  <>
                    {isSmallScreen ? (
                      <Svg name="artem-small-icon-new" />
                    ) : (
                      <Icon
                        className="w-15 h-12"
                        name="artem-color-icons"
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="absolute top-1/2 left-[250px]  h-8 -translate-y-1/2">{Secondary}</div>
          {children && (
            <div
              // className={`custom-scrollbar absolute left-1/2 top-1/2 max-h-screen max-w-full -translate-x-1/2 -translate-y-1/2 transform overflow-auto rounded-l-lg bg-white bg-opacity-30 sm:max-w-[90%] md:max-w-[76%] ${
              //   isOpen === 'true' && 'rounded-r-lg'
              //   }  ${isSmallScreen === true && isOpenLayout === 'true' && 'left-[30%]'}`}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-white bg-opacity-30"
            >
              <div className="flex items-center justify-center space-x-2">{children}</div>
            </div>
          )}
        </div>
        {/*   <div className="absolute right-0 top-1/2 flex -translate-y-1/2 select-none items-center">
          {showPatientInfo !== PatientInfoVisibility.DISABLED && (
            <HeaderPatientInfo
              servicesManager={servicesManager}
              appConfig={appConfig}
            />
          )}
          <div className="border-primary-dark mx-1.5 h-[25px] border-r"></div>
         <div className="flex-shrink-0">
            <Dropdown
              id="options"
              showDropdownIcon={false}
              list={menuOptions}
              alignment="right"
            >
              <IconButton
                id={'options-settings-icon'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active hover:bg-primary-dark h-full w-full"
              >
                <Icon name="icon-settings" />
              </IconButton>
            </Dropdown>
          </div>
        </div>*/}
        <div className="flex flex-grow items-center justify-end gap-3">
          {token && params.size !== 0 && (
            <div className="absolute right-0 top-1/2 mr-2 flex -translate-y-1/2 cursor-pointer select-none items-center gap-3 rounded-lg bg-white bg-opacity-30 p-1 px-3">
              <div className="flex">
                <button className="text-common-bright !text-common-bright hover:!text-primary-light flex inline-flex items-center justify-center rounded-md rounded p-1 text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100">
                  <Icon
                    name="3D-Icon"
                    // className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light inline-flex h-5 w-4 items-center justify-center rounded-md border-white text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
                    onClick={() => {
                      const inobeticUrl = `inobitec://?pacs-aetitle=ARTEM&patient-id=${JSON.parse(localStorage.getItem('single_study'))[0]['00100020'].Value[0]}&series-uid=${seriesInstanceUIDs}&open-series-images`;

                      // const inobeticUrl = `inobitec://?pacs-aetitle=ARTEM&patient-id=${JSON.parse(localStorage.getItem('single_study'))[0]['00100020'].Value[0]}&series-uid=${seriesInstanceUIDs}&open-series-images`;
                      window.open(inobeticUrl, '_blank');
                    }}
                  />
                </button>
              </div>
              <div className="border-white-active border-l py-3"></div>
              <div className="flex">
                <button className="text-common-bright !text-common-bright hover:!bg-primary-dark1 hover:!text-primary-light inline-flex items-center justify-center rounded-md rounded p-1 text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100">
                  <Icon
                    name="av-icon-inobitec"
                    // className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light flex inline-flex h-5 w-4 cursor-pointer items-center justify-center rounded-md text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
                    onClick={() => {
                      // const aviewer = `dicomviewer://viewer?seriesId=${seriesInstanceUIDs}&studyId=${StudyInstanceUIDs}`;
                      // Define the API endpoint
                      const apiUrl =
                        'https://dev-phase2.bmchealth.in/api/api/v1/radiology/encounter-service-report/accessAVS';

                      // Define the data to send in the request body
                      const data = {
                        dicom_study_uid: StudyInstanceUIDs, //'1.3.12.2.1107.5.8.15.290224.30000024062618252296300000007',
                        dicom_series_uid: seriesInstanceUIDs, //'1.3.12.2.1107.5.8.15.290224.30000024062618252296300000008',
                      };
                      const localUrl = 'http://localhost:5000';

                      // Make the POST request
                      fetch(localUrl, {
                        method: 'GET', // HTTP method
                        headers: {
                          'Content-Type': 'application/json', // Set content type
                        },
                        // body: JSON.stringify(data), // Convert data to JSON string
                      })
                        .then(response => {
                          if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                          }
                          return response.json(); // Parse JSON response
                        })
                        .then(responseData => {
                          console.log('responseDataresponseData', responseData);
                        })
                        .catch(error => {
                          console.error('Error making POST request:', error); // Handle errors
                        });
                      fetch(apiUrl, {
                        method: 'POST', // HTTP method
                        headers: {
                          'Content-Type': 'application/json', // Set content type
                          Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data), // Convert data to JSON string
                      })
                        .then(response => {
                          if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                          }
                          return response.json(); // Parse JSON response
                        })
                        .then(responseData => {
                          const { requested_user_token, avs_study_request_id } =
                            responseData.responseObject;

                          const aviewer = `artemviewer://viewer?avs_study_request_id=${avs_study_request_id}&requested_user_token=${requested_user_token}`;
                          window.open(aviewer, '_blank');
                        })
                        .catch(error => {
                          console.error('Error making POST request:', error); // Handle errors
                        });

                      // window.open(aviewer, '_blank');
                    }}
                  />
                </button>
              </div>
              {/* <div className="border-white-active border-l py-3"></div> */}
              {/* <div onClick={() => onClickTab(!isLightTheme)}>
              <Icon
              name="moon-icon"
              className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light ml-1 inline-flex h-5 w-4 items-center justify-center rounded-md border-white text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100"
              />
              </div> */}
            </div>
          )}
        </div>
        {/* ) */}
        {/* : ( */}
        {/* <div onClick={() => onClickTab(!isLightTheme)}>
            <Icon
              name="dark-theme-icon"
              className="text-common-bright !text-common-bright hover:!bg-primary-dark hover:!text-primary-light inline-flex w-10 items-center justify-center rounded-md rounded border-white text-center text-lg font-bold outline-none transition duration-300 ease-in-out hover:opacity-80 focus:opacity-80 focus:outline-none active:opacity-100 "
            />
          </div> */}
        {/* // ) */}
        {/* // } */}
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  showPatientInfo: PropTypes.string,
  servicesManager: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
